import { fromHtmlString } from "../index.js";
export function applyDiffChange(diffChange, oldSection) {
    // const patch = DiffChange.diff.patch_fromText(diffChange.diffText);
    // const [newSection, _] = DiffChange.diff.patch_apply(patch, oldSection.toString());
    return fromHtmlString(diffChange.newText);
}
export class DiffChange {
    // public static diff = new diff_match_patch();
    constructor(startId, endId, newText) {
        this.startId = startId;
        this.endId = endId;
        this.newText = newText;
    }
    static fromBooks(startId, originalBook, newBook) {
        // const diff = DiffChange.diff.patch_make(originalBook.toString(), newBook.toString());
        // const diffText = DiffChange.diff.patch_toText(diff);
        // return new DiffChange(startId, startId + originalBook.size() - 1, diffText);
        return new DiffChange(startId, startId + originalBook.size() - 1, newBook.toString());
    }
    toJSON() {
        return {
            startId: this.startId,
            endId: this.endId,
            newText: this.newText,
            type: "diffChange"
        };
    }
    static fromJSON(json) {
        return new DiffChange(json.startId, json.endId, json.newText);
    }
}
