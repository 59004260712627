import Cookies from 'universal-cookie';
const ACCESS_TOKEN = "AccessToken";
const ID_TOKEN = "IdToken";
const REFRESH_TOKEN = "RefreshToken";
const EXPIRE_TIME = "ExpireTime";
export class CookieManager {
    constructor(origin) {
        this.origin = origin;
        this.cookies = new Cookies();
        this.accessToken = this.cookies.get(ACCESS_TOKEN);
        this.idToken = this.cookies.get(ID_TOKEN);
        this.refreshToken = this.cookies.get(REFRESH_TOKEN);
        const expireDateString = this.cookies.get(EXPIRE_TIME);
        this.expireDate = (expireDateString === undefined) ? new Date() : new Date(expireDateString);
    }
    setTokensFromResponse(response_data) {
        this.accessToken = response_data.access_token;
        this.idToken = response_data.id_token;
        this.refreshToken = response_data.refresh_token;
        this.expireDate = new Date(new Date().getTime() + 60 * 60 * 1000);
        this.setCookies();
    }
    setTokensFromRefreshResponse(response_data) {
        response_data.refresh_token = this.refreshToken;
        this.setTokensFromResponse(response_data);
    }
    setCookies() {
        let domain = "lumoslogos.com";
        if (this.origin.endsWith("dev.lumoslogos.com")) {
            domain = "dev.lumoslogos.com";
        }
        else if (this.origin === "http://localhost:3000") {
            domain = "localhost";
        }
        const cookie_options = {
            expires: this.expireDate,
            domain
        };
        this.cookies.set(ACCESS_TOKEN, this.accessToken, cookie_options);
        this.cookies.set(ID_TOKEN, this.idToken, cookie_options);
        this.cookies.set(REFRESH_TOKEN, this.refreshToken, cookie_options);
        this.cookies.set(EXPIRE_TIME, this.expireDate, cookie_options);
    }
    isAuthenticated() {
        if (this.expireDate === undefined) {
            return false;
        }
        if (this.accessToken === undefined) {
            return false;
        }
        if (new Date().getTime() > this.expireDate.getTime()) {
            return false;
        }
        return true;
    }
    getAccessToken() {
        return this.accessToken;
    }
    getIdToken() {
        return this.idToken;
    }
    getRefreshToken() {
        return this.refreshToken;
    }
    getExpireDate() {
        return this.expireDate;
    }
}
