export class BookPartContainer {
    constructor(children) {
        this.children = [];
        this.setChildren(children);
    }
    setChildren(children) {
        this.children = [];
        this.children.push(...children.map((child) => child.copy()));
    }
    getChildren() {
        const returnChildren = [];
        returnChildren.push(...this.children.map((child) => child.copy()));
        return returnChildren;
    }
}
