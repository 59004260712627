import { formatTextForLatex } from '../index.js';
export class BookPartTitle {
    constructor(title) {
        this.title = title;
    }
    toLatex(context) {
        if (this.title.startsWith("*"))
            return `\\${this.latexKeyword}*{${formatTextForLatex(this.title.substring(1, this.title.length))}}\n`;
        return `\\${this.latexKeyword}{${formatTextForLatex(this.title)}}\n`;
    }
    clean() {
        const newTitle = this.copy();
        newTitle.title = newTitle.title.trim();
        return [newTitle];
    }
}
