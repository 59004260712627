import React from "react";
import { BookPartContainer } from "./BookPartContainer.js";
import { convertNodeList } from "../index.js";
import { HTMLElement } from "node-html-parser";
import { homeOrphanWords } from "../functions/homeOrphanWords.js";
export class Quote extends BookPartContainer {
    constructor() {
        super(...arguments);
        this.xmlKeyword = "quote";
    }
    copy() {
        return new Quote(this.getChildren());
    }
    toLatex(context) {
        const childrenLatex = [];
        for (let ii = 0; ii < this.children.length; ii++) {
            const child = this.children[ii];
            const previousBookPart = ii > 0 ? this.children[ii - 1] : null;
            const nextBookPart = ii < this.children.length - 1 ? this.children[ii + 1] : null;
            context.previousBookPart;
            childrenLatex.push(child.toLatex({ previousBookPart, nextBookPart, chapterNewPage: context.chapterNewPage }));
        }
        const insideContent = childrenLatex.join("\n");
        return `\\begin{displayquote}\n${insideContent}\\end{displayquote}\n`;
    }
    toJSX() {
        return React.createElement("blockquote", { id: Quote.QUOTE_ATTRIBUTE }, this.getChildren().map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); }));
    }
    toString() {
        return `<blockquote id="${Quote.QUOTE_ATTRIBUTE}">${this.getChildren().map((child) => child.toString()).join("")}</blockquote>`;
    }
    fromHTML(node) {
        return [new Quote(convertNodeList(node.childNodes))];
    }
    isHTML(node) {
        return Quote.isHTML(node);
    }
    static isHTML(node) {
        if (node === undefined || node === null)
            return false;
        if (!(node instanceof HTMLElement))
            return false;
        if (!(node.hasAttribute("id") && node.getAttribute("id") === Quote.QUOTE_ATTRIBUTE))
            return false;
        return node.rawTagName === "blockquote";
    }
    clean() {
        const children = this.children.map((child) => child.copy());
        if (children.length === 0)
            return [];
        const newChildren = homeOrphanWords(children).flatMap((child) => child.clean());
        if (newChildren.length === 0)
            return [];
        const copy = this.copy();
        copy.setChildren(newChildren);
        return [copy];
    }
}
Quote.QUOTE_ATTRIBUTE = "data_node_quote";
Quote.REMIRROR_STYLES = {
    "font-style": "normal"
};
