import { CookieManager } from "./CookieManager.js";
import { ApiManager } from "./ApiManager.js";
import { StoredDiff } from "bookdata";
import { reviver } from "bookapiinterface";
import { RequestExecutor } from "./RequestExecutor.js";
import { MessageType, defaultMessageHandler } from "./MessageHandler.js";
export class UserManager {
    constructor(origin, isServer = false) {
        this.isServer = isServer;
        this.messageHandler = defaultMessageHandler;
        this.cookieManager = (isServer) ? null : new CookieManager(origin);
        this.apiManager = new ApiManager(origin, isServer);
        this.requestExecutor = new RequestExecutor();
        this.attemptProvideApiAuth();
    }
    setMessageHandler(messageHandler) {
        this.messageHandler = messageHandler;
        this.requestExecutor.setMessageHandler(messageHandler);
    }
    async heartbeat() {
        if (this.cookieManager === null)
            return;
        const expireDate = this.cookieManager.getExpireDate()?.getTime();
        if (expireDate === undefined)
            return;
        const now = Date.now();
        if (now >= expireDate) {
            return;
        }
        const refreshToken = this.cookieManager.getRefreshToken();
        if (refreshToken === undefined)
            return;
        if (now > (expireDate - UserManager.TEN_MINUTES)) {
            this.apiManager.sendRefreshToken(refreshToken).then((response) => {
                if (this.cookieManager === null)
                    return;
                this.cookieManager.setTokensFromRefreshResponse(response.data);
                this.attemptProvideApiAuth();
                return;
            });
        }
    }
    async awaitPutRequests() {
        return await this.requestExecutor.awaitPutsInQueue();
    }
    async loginUser(code) {
        const response = await this.apiManager.sendAuthCode(code);
        if (this.cookieManager === null)
            return;
        this.cookieManager.setTokensFromResponse(response.data);
        this.attemptProvideApiAuth();
    }
    attemptProvideApiAuth() {
        if (this.cookieManager === null)
            return;
        const accessToken = this.cookieManager.getAccessToken();
        const idToken = this.cookieManager.getIdToken();
        if (accessToken === undefined || idToken === undefined) {
            return;
        }
        this.provideAuth(accessToken, idToken);
    }
    isLoggedIn() {
        if (this.cookieManager === null)
            return false;
        return this.cookieManager.isAuthenticated();
    }
    getName() {
        if (this.cookieManager === null)
            return "";
        const idToken = this.cookieManager.getIdToken();
        if (idToken === undefined) {
            return "";
        }
        const decodedIdToken = this.parseJwt(idToken);
        return decodedIdToken.name;
    }
    provideAuth(accessToken, idToken) {
        this.apiManager.setAccessToken(accessToken);
        this.apiManager.setIdToken(idToken);
    }
    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    getLoginUrl() {
        return this.apiManager.getLoginUrl();
    }
    async getToC(bookId) {
        return this.requestExecutor.execute(async () => {
            return await this.apiManager.getToC(bookId).then((response) => {
                return JSON.parse(JSON.stringify(response.data), reviver);
            });
        }, false);
    }
    async getBookMetadata(bookId) {
        return (await this.apiManager.getBookMetadata(bookId)).data;
    }
    async getAllBookMetadata() {
        return (await this.apiManager.getAllBookMetadata()).data;
    }
    async getBookContent(bookId, startId, endId, config = null) {
        return this.requestExecutor.execute(async () => {
            return this.apiManager.getBookContent(bookId, startId, endId, config).then((response) => {
                return JSON.parse(JSON.stringify(response.data), reviver);
            });
        }, false);
    }
    async putLogChanges(bookId, logChanges) {
        const request = {
            diffChange: logChanges
        };
        return this.requestExecutor.execute(async () => {
            return this.apiManager.putLogChanges(bookId, request).then((response) => {
                return JSON.parse(JSON.stringify(response.data), reviver);
            });
        }, true);
    }
    async putBookMetadata(bookId, title, author, translator = undefined) {
        let putBookMetadataRequest = {
            metadata: {
                title,
                author,
                bookId
            }
        };
        if (translator !== undefined && translator.length > 0) {
            putBookMetadataRequest = {
                metadata: {
                    ...putBookMetadataRequest.metadata,
                    translator
                }
            };
        }
        const messageId = this.messageHandler.createMessage("Saving new metadata...", MessageType.Loading);
        try {
            await this.apiManager.putBookMetadata(putBookMetadataRequest);
            this.messageHandler.updateMessage(messageId, "Successfully saved metadata", MessageType.Success);
        }
        catch (e) {
            this.messageHandler.updateMessage(messageId, "Error saving metadata", MessageType.Error);
            console.error(e);
        }
    }
    async putNewBook(title, author, content, translator = undefined, parser = undefined) {
        let putNewBookRequest = {
            metadata: {
                title,
                author,
                bookId: ""
            },
            content
        };
        if (translator !== undefined && translator.length > 0) {
            putNewBookRequest = {
                content: putNewBookRequest.content,
                metadata: {
                    ...putNewBookRequest.metadata,
                    translator
                }
            };
        }
        if (parser !== undefined && parser.length > 0) {
            putNewBookRequest = {
                ...putNewBookRequest,
                parser
            };
        }
        const messageId = this.messageHandler.createMessage("Creating new book...", MessageType.Loading);
        try {
            const returnVal = await this.apiManager.putNewBook(putNewBookRequest);
            this.messageHandler.updateMessage(messageId, "Successfully created book", MessageType.Success);
            return returnVal;
        }
        catch (e) {
            this.messageHandler.updateMessage(messageId, "Error creating book", MessageType.Error);
            console.error(e);
        }
    }
    async createBookPdf(bookId, options = {}) {
        return this.apiManager.createBookPdf(bookId, { options: options }).then((response) => {
            return response.data;
        });
    }
    async getBookPdfUrl(bookId, token) {
        return this.apiManager.getBookPdfUrl(bookId, token).then((response) => {
            return response.data;
        });
    }
    async getAllDiffs(bookId) {
        const rawResponse = await this.apiManager.getAllDiffs(bookId);
        const response = JSON.parse(JSON.stringify(rawResponse.data));
        return response.diffs;
    }
    async getBookDiff(bookId, startId, endId) {
        const id = this.messageHandler.createMessage("Getting book changes...", MessageType.Loading);
        const rawResponse = await this.apiManager.getBookDiff(bookId, startId, endId);
        this.messageHandler.deleteMessage(id);
        const response = JSON.parse(JSON.stringify(rawResponse.data), reviver);
        return response.diff;
    }
    async deleteBook(bookId) {
        await this.apiManager.deleteBook(bookId);
    }
    static async getStoredDiffsFromS3Url(s3Url) {
        // Download content from s3Url and parse it into StoredDiff
        const response = await fetch(s3Url);
        const content = await response.text();
        const jsonDiffList = JSON.parse(content);
        return jsonDiffList.map(StoredDiff.fromJSON);
    }
}
UserManager.TEN_MINUTES = 10 * 60 * 1000;
