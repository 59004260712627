import React from "react";
import { BookPartTitle } from "./BookPartTitle.js";
import { HTMLElement } from "node-html-parser";
import { formatTextForString, decodeTextFromString } from "../index.js";
export class Chapter extends BookPartTitle {
    constructor() {
        super(...arguments);
        this.latexKeyword = "chapter";
    }
    copy() {
        return new Chapter(this.title);
    }
    getLevel() {
        return 2;
    }
    toLatex(context) {
        let extraBeginning = "";
        let extraEnding = "";
        if (!context.chapterNewPage) {
            extraBeginning = "\\begingroup\n\\let\\clearpage\\relax\n";
            extraEnding = "\\endgroup\n";
        }
        return extraBeginning + super.toLatex(context) + extraEnding;
    }
    toJSX() {
        return React.createElement("h2", null, this.title);
    }
    toString() {
        return `<h2>${formatTextForString(this.title)}</h2>`;
    }
    fromHTML(node) {
        return [new Chapter(decodeTextFromString(node.innerText))];
    }
    isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        return node.rawTagName === "h2";
    }
}
