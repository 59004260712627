import React from 'react';
import { BookPartTitle } from './titles/BookPartTitle.js';
import { TableOfContents } from './tableOfContents/TableOfContents.js';
import { generateBottomFootnoteBook } from './functions/bottomFootnote.js';
import { homeOrphanWords } from './functions/homeOrphanWords.js';
export class Wrapper {
    constructor(children) {
        this.children = children;
    }
    toJSX() {
        return (React.createElement("div", { id: "book" }, this.children.map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); })));
    }
    toJSXBottomFootnotes() {
        const [newChildren, footnotes] = generateBottomFootnoteBook(this.getChildren());
        return (React.createElement("div", { id: "book" },
            newChildren.map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); }),
            React.createElement("div", { id: "footnotes" }, footnotes.map((footnote, indx) => { return React.cloneElement(footnote.toJSX(), { key: "f" + indx.toString() }); }))));
    }
    toString() {
        return this.children.map((child) => { return child.toString(); }).join("");
    }
    getLatexPreamble(title, author, translator) {
        if (translator.length > 0)
            author = `${author} \\\\ Translated by ${translator}`;
        return `\\documentclass[openany,ebook,twoside]{memoir}
\\usepackage{fancyhdr}
\\usepackage{fontspec}
\\usepackage{librecaslon}
\\usepackage{gfsartemisia}
\\setlrmarginsandblock{.675in}{.3in}{*}
\\setulmarginsandblock{.5in}{.75in}{*}
\\checkandfixthelayout 
\\setmainfont{LibreCaslonText-Regular.otf}[
        BoldFont = LibreCaslonText-Bold.otf ,
        ItalicFont = LibreCaslonText-Italic.otf ,
        BoldItalicFont = LibreCsln-BoldItalic.otf]
\\newfontfamily\\greekfont{GFSArtemisia.otf}[
    BoldFont = GFSArtemisiaBold.otf,
    ItalicFont = GFSArtemisiaIt.otf ,
    BoldItalicFont = GFSArtemisiaBoldIt.otf]

    
\\usepackage{polyglossia}
\\usepackage{csquotes}
\\tolerance=1
\\emergencystretch=\\maxdimen
\\hyphenpenalty=10000
\\hbadness=10000

\\setmainlanguage[variant=british]{english}
\\setotherlanguage[variant=polytonic]{greek}
\\OnehalfSpacing
\\renewcommand{\\headrulewidth}{0pt}
\\renewcommand*{\\chaptitlefont}{\\normalfont\\large\\bfseries}
\\nonzeroparskip
% Turn on the style
\\pagestyle{fancy}
% Clear the header and footer
\\fancyhead[L]{}
\\fancyhead[R]{}
\\fancyfoot{}

% Set the right side of the footer to be the page number
\\fancyfoot[C]{\\thepage}
\\begin{document}
\\author{${author}}
\\title{${title}}
\\date{}
\\frontmatter
\\maketitle
\\thispagestyle{plain} % empty
\\newpage
\\tableofcontents
\\mainmatter\n`;
    }
    getLatexEbookPreamble(title, author, translator) {
        if (translator.length > 0)
            author = `${author} \\\\ Translated by ${translator}`;
        return `\\documentclass[openany,ebook]{memoir}
\\usepackage[english,greek]{babel}
\\usepackage[T1]{fontenc}
\\usepackage[utf8]{inputenc}
\\begin{document}
\\author{${author}}
\\title{${title}}
\\date{}
\\frontmatter
\\maketitle
\\newpage
\\tableofcontents
\\mainmatter\n`;
    }
    getLatexPostamble() {
        return `\n\\end{document}`;
    }
    toLatex(title, author, translator = "", ebook = false, chapterNewPage = true) {
        const childrenLatex = [];
        for (let ii = 0; ii < this.children.length; ii++) {
            const child = this.children[ii];
            const previousBookPart = ii > 0 ? this.children[ii - 1] : null;
            const nextBookPart = ii < this.children.length - 1 ? this.children[ii + 1] : null;
            childrenLatex.push(child.toLatex({ previousBookPart, nextBookPart, chapterNewPage }));
        }
        let mainMatter = childrenLatex.join("");
        if (ebook) {
            mainMatter = mainMatter.replaceAll("\\begin{greek}", "");
            mainMatter = mainMatter.replaceAll("\\end{greek}", "");
        }
        const preamble = ebook ? this.getLatexEbookPreamble(title, author, translator) : this.getLatexPreamble(title, author, translator);
        return preamble + mainMatter + this.getLatexPostamble();
    }
    copy() {
        return new Wrapper(this.children.map((child) => child.copy()));
    }
    // Return toc where bottom level is equal to parameter leafLevel
    // E.g. leafLevel = Chapter
    getToc() {
        const tableOfContents = new TableOfContents();
        let counter = 0;
        for (const child of this.children) {
            if (child instanceof BookPartTitle) {
                tableOfContents.addContent(child, counter);
            }
            counter++;
        }
        return tableOfContents;
    }
    getChildren() {
        return this.children.map((child) => child.copy());
    }
    size() {
        return this.children.length;
    }
    clean() {
        const children = this.children.map((child) => child.copy());
        const newChildren = homeOrphanWords(children);
        return new Wrapper(newChildren.flatMap((child) => { return child.clean(); }));
    }
    toJSON() {
        return {
            html: this.toString(),
            type: "wrapper"
        };
    }
}
